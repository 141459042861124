import React from 'react'
import Meta from '../components/Meta'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import PageHeading from '../components/PageHeading'
import Footer from '../components/Footer'
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEnvelope,
    faPhone,
    faMapMarker,
    faMobileAlt
} from '@fortawesome/free-solid-svg-icons'

class ContactRoute extends React.Component {
    render() {
        const contactPageData = this.props.data
        const postfix = contactPageData.kontentItemSiteMetadata.elements.title.value
        const kontentItemContactPage = contactPageData.kontentItemContactPage
        const elements = kontentItemContactPage.elements
        var pageTitle = elements.title.value
        var pageSubtitle = elements.subtitle.value
        var metaDscription = elements.meta_description.value
        var email = elements.email.value
        var emailTo = "mailto:" + email
        var phone = elements.phone.value
        var callTo = "tel:" + phone
        var phoneMobile = elements.phone_mobile.value
        var callToMobile = "tel:" + phoneMobile

        const address = elements.address.value

        return (
            <Layout>
                <div>
                    <Meta title={pageTitle} postfix={postfix} description={metaDscription} />
                    <Header isContactPage />
                    <PageHeading data={pageSubtitle} />

                    <section className="contact-info">
                        <div className="container">
                            <div className="row align-items-start">
                                <div className="col-md-6">
                                    <a href={emailTo} data-tracking-label="Contact_Email">
                                        <div className="info-item info-item__email">
                                            <div className="icon">
                                                <FontAwesomeIcon icon={faEnvelope} size="1x" />
                                            </div>
                                            <h2>Email</h2>
                                            <p>{email}</p>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6">
                                    <a href={callTo} data-tracking-label="Contact_Phone">
                                        <div className="info-item info-item__tel">
                                            <div className="icon">
                                                <FontAwesomeIcon icon={faMobileAlt} size="1x" />
                                            </div>
                                            <h2>Telefon</h2>
                                            <p>{phone}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="map" id="mapa">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h2>Kde nás najdete</h2>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.7211755139406!2d16.60233471568606!3d49.186877279321095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47129454a8f0447f%3A0x26cf83735a83dbcb!2sNov%C3%A9%20sady%20946%2F30%2C%20602%2000%20Brno-st%C5%99ed-Star%C3%A9%20Brno!5e0!3m2!1sen!2scz!4v1612565473024!5m2!1sen!2scz" allowFullScreen=""></iframe>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="contact-address">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3">
                                    <h2>Adresa</h2>
                                    <RichTextElement
                                        value={address} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer isContactPage />
                </div>
            </Layout>
        )
    }
}

export default ContactRoute

export const pageQuery = graphql`
  query ContactQuery($codename: String!) {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
      }
    }
    kontentItemContactPage(system: {codename: {eq: $codename}}) {
      id
      system {
        id
      }
      elements {
        title {
          value
        }
        subtitle {
          value
        }
        meta_description {
          value
        }
        email {
          value
        }
        phone {
          value
        }
        phone_mobile {
          value
        }
        address {
          value
        }
      }
    }
  }
`